import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { MatBottomSheet } from '@angular/material';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { BottomSheetMessageComponent } from 'app/components';
import { IUbcHttpRequest, SheetData } from 'app/models';

export abstract class HttpClientService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private bottomSheetService: MatBottomSheet) { }

  /**
   * Retrieves a payload of type T.
   * @param request the request.
   */
  public get<T>(request: IUbcHttpRequest): Observable<T> {

    return this.httpClient.get<T>(request.uri, {
      headers: request.headers,
      params: request.params
    }).pipe(
      retry(3),
      catchError(err => this.handleError(err, this.router)));
  }

  /**
   * Handles error - stolen from angular.io.. hehe
   * @param error error response.
   */
  private handleError(error: HttpErrorResponse, router: Router): Observable<null> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);

      this.bottomSheetService.open(BottomSheetMessageComponent, {
        data: <SheetData>{
          message: error.error.message
        }
      });
    } else {

      if (error.status === 403) {
        router.navigate(['/signin'], {
          queryParamsHandling: 'merge'
        });
      }

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return of(null);
  }
}
