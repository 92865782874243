import { isDevMode, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { MatBottomSheet } from '@angular/material';

import { PWCEnvironment, PWCApi } from 'app/models';
import { HttpClientService } from 'app/services/http-client.service';
import { environment } from 'src/environments/environment';
import { RepositoryType } from 'app/models/repository-type';

@Injectable()
export class PathwaysPortalClientService extends HttpClientService {

  constructor(private angularRouter: Router, private http: HttpClient, private bottomSheet: MatBottomSheet) {
    super(angularRouter, http, bottomSheet);
  }

  protected getSupportUpdatedEndpoint(env: PWCEnvironment, repo: RepositoryType, api: string, action: string): string {
    return `${isDevMode() ? 'http' : 'https'}://${environment.supportApiEndpoint}/support/env/${env}/repo/${repo}/api/${api}/${action}`;
  }

  /**
   * Get support tool endpoint.
   * @param api the API for which the request is for.
   * @param env the environment for which the request is for.
   */
  protected getSupportEndpoint(api: PWCApi, env: PWCEnvironment): string {
    return `${isDevMode() ? 'http' : 'https'}://${environment.supportApiEndpoint}/support/env/${env}/repo/${api}/api/`;
  }

  protected getUserEndpoint(): string {
    return `${isDevMode() ? 'http' : 'https'}://${environment.supportApiEndpoint}/identity/user`;
  }
}
