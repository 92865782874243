import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { User } from 'app/models';

@Component({
  selector: 'ubc-nav',
  templateUrl: './top-nav.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavComponent {
  // @Input() public user: User;
}
