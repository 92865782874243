import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { SheetData } from 'app/models';

@Component({
  selector: 'ubc-bottom-sheet-message',
  templateUrl: './bottom-sheet-message.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomSheetMessageComponent {

  public errorMessage: string;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetMessageComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: SheetData
  ) {
    this.errorMessage = data.message;
  }
}
