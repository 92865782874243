export const environmentIdParam = 'envId';
export const clientIdParam = 'clientId';

export enum PWCEnvironment {
  None = 0,
  Beta,
  Development,
  Testing,
  Training,
  Production
}

export enum PWCApi {
  None = 0,
  BenefitsMedical,
  BenefitsPharmacy,
  Cases,
  CreditCheck,
  Epa = 5,
  Erx,
  Identity,
  Lookups,
  Organizations,
  Patients = 10,
  Pharmacies,
  Physicians,
  Plans,
  Products,
  Protect = 15,
  Registry,
  Reimbursement,
  Services,
  Signatures
}
