import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ubc-signout',
  templateUrl: './signout.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
