import {
  ChangeDetectionStrategy,
  Inject,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation

} from '@angular/core';

import { Subscription } from 'rxjs';

@Component({
  selector: 'ubc-landing',
  templateUrl: './landing.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent implements OnDestroy, OnInit {

  public chosenEnvironment: string;

  private subscriptions = new Array<Subscription>();


  public ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public ngOnInit() { }
}
