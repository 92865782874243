import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { User } from 'app/models';
import { PwcIdentityClientService } from 'app/services';
import { filter, tap } from 'rxjs/operators';


@Component({
  selector: 'ubc-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  // public user: Observable<User>;

  // constructor(
  //   private router: Router,
  //   private identityService: PwcIdentityClientService
  // ) { }

  public ngOnInit(): void {
    // cheap way to avoid a route resolver
    // this.user =
    //   this.identityService.getUserName()
    //     .pipe(
    //       filter((user: User) => user !== null && user.name !== ''),
    //       tap(() => this.router.navigate(['engage'])));
  }
}
