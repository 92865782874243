import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent, PageNotFoundComponent, SigninComponent } from 'app/components';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'portal',
    loadChildren: 'app/modules/portal/portal.module#PortalModule'
  },
  {
    path: 'meta',
    loadChildren: 'app/modules/meta/meta.module#MetaModule'
  },
  {
    path: 'tool',
    loadChildren: 'app/modules/tool/tool.module#ToolModule'
  },
  {
    path: 'signin',
    component: SigninComponent,
    pathMatch: 'full'
  },
  {
    path: 'engage',
    loadChildren: 'app/modules/engage-demo/engage-demo.module#EngageDemoModule'
  },
  {
    path: 'medpro',
    loadChildren: 'app/modules/medpro/medpro-hcp/medpro-hcp.module#MedProHcpModule'
  },
  {
    path: 'medpro-hco',
    loadChildren: 'app/modules/medpro/medpro-hco/medpro-hco.module#MedProHcoModule'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
