import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatBottomSheet } from '@angular/material';

import { AppConfigOptions, SheetData } from 'app/models';
import { BottomSheetMessageComponent } from 'app/components';

@Injectable()
export class ToolConfigGuard implements CanActivate, CanActivateChild {

  constructor(private bottomSheet: MatBottomSheet) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivateRoute(route, state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivateRoute(route, state);
  }

  private canActivateRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const options = <AppConfigOptions>route.queryParams;

    const result = options.envId !== undefined || options.clientId !== undefined;

    if (!result) {
      this.bottomSheet.open(BottomSheetMessageComponent, {
        data: <SheetData>{
          message: 'Please select an Environment and Client before proceeding.'
        }
      });
    }

    return result;
  }
}
