import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { PathwaysPortalClientService } from 'app/services/pathways-portal-client.service';
import { PWCEnvironment, ServiceType, ActivityType, PwcClient, PagedArray, User, IUbcHttpRequest } from 'app/models';
import { RepositoryType } from 'app/models/repository-type';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PwcIdentityClientService extends PathwaysPortalClientService {

  public getClients(env: PWCEnvironment): Observable<PagedArray<Array<PwcClient>>> {
    const endpoint = this.getSupportUpdatedEndpoint(env, RepositoryType.Identity, ActivityType.Client, ServiceType.All);

    return this.get<PagedArray<Array<PwcClient>>>(<IUbcHttpRequest>{
      uri: endpoint
    });
  }

  public getUserName(): Observable<User> {
    const endpoint = this.getUserEndpoint();

    return this.get<User>(<IUbcHttpRequest>{
      uri: endpoint
    });
  }

}
