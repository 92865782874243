import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import {
  MatToolbarModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatMenuModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatSortModule,
  MatGridListModule,
  MatExpansionModule,
  MatBottomSheetModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTableModule,
} from '@angular/material';

import { PrettyJsonModule } from 'angular2-prettyjson';

import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  BottomSheetMessageComponent,
  LandingComponent,
  PageNotFoundComponent,
  TopNavComponent,
  SigninComponent,
  SignoutComponent,
} from 'app/components';
import { ToolConfigGuard } from 'app/modules/tool/guards';
import { PathwaysPortalClientService, PwcIdentityClientService } from 'app/services';
import { AppConfig } from './shared/configuration/app.config';
import { TitleCasePipe } from '@angular/common';

export function InitializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LandingComponent,
    TopNavComponent,
    BottomSheetMessageComponent,
    SigninComponent,
    SignoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatGridListModule,
    MatSortModule,
    MatGridListModule,
    MatPaginatorModule,
    MatExpansionModule,
    AppRoutingModule,
    PrettyJsonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    FormsModule,
  ],
  providers: [
    AppConfig,
    ToolConfigGuard,
    { provide: APP_INITIALIZER, multi: true, deps: [AppConfig], useFactory: InitializeApp },
    PathwaysPortalClientService,
    PwcIdentityClientService,
    TitleCasePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [BottomSheetMessageComponent],
})
export class AppModule {}
